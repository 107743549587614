
.loading, .success, .anim {
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leadingsuccess {
  height: 420px;
  transition: height ease-out .5s 2s;
  margin-bottom: 20px;
}

.leadingsuccess + div {
  opacity: 0;
  transition: opacity ease-out .5s 2s;
}

.success.leadingsuccess {
  height: 120px;
}

.success.leadingsuccess + div {
  opacity: 1;
}

.success + div h3:first-child {
  text-align: center;
  animation: up 1.5s ease-out 1;
  margin: -7.5em 0;
}

@keyframes up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.leadingsuccess + div h3:first-child {
  padding-bottom: 1.5em;
  margin: 0 0 1.5em 0;
  border-bottom: 1px solid #eee;
}

.note {
  color: #aa8941;
  border-radius: .5em;
  font-size: .9em;
  padding: .8em 1em;
  margin: 1.3em -.7em .5em;
  background: rgba(255, 174, 0, 0.07);
  ;
}

.note b {
  font-weight: 600;
}

.table {
  margin-bottom: .5em;
  
}

.table td {
  vertical-align: top;
  padding-right: .5em;
  max-width: 230px;
}

.table td:first-child {
  font-weight: bold;
}


.subtext {
  color: #777;
}

.hint {
  font-size: .875em;
  margin: .2em 0 1.5em;
  color: #777;
  text-align: center;
  width: 268px;
}

.verifyurl {
  background: #eee;
  border-radius: .3em;
  padding: .5em .8em;
  width: 270px;
  text-align: center;
  box-sizing: border-box;
  margin: 1em -1px .2em;
  font-size: .8em;
  font-weight: bold;
}

.verifyurl svg {
  margin-right: .6em;
  vertical-align: -2%;
}

.verifyurl svg + span {
  color: #093
}

.inputfield {
  margin-bottom: .5em;
}

.inputfield input:not([type=radio]),
.inputfield .cardcontainer,
.inputfield select {
  border-radius: .2em;
  font-size: 17px;
  padding: .5em .8em;
  position: relative;
  border: 0;
  box-shadow: 0 0 0 1px #ddd;
  transition: all .2s ease-out;
  background-color: whitesmoke;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 268px;
}

.inputfield .cardcontainer {
  width: 324px;
  height: 37.39px;
}

.inputfield .cardcontainer.error + div {
  color: orange
}

.inputfield input + select {
  margin: 0 0 0 -4em;
  width: 4em;
  padding: .5em .4em;
  background-position-x: 88%;
  box-shadow: none;
  background-color: transparent;
}

.inputfield select[required]:invalid {
  color: #aaa;
}

.inputfield input[type=radio] {
  border-radius: 50%;
}

form:not([novalidate]) .inputfield input:invalid,
form:not([novalidate]) .inputfield select:invalid,
form:not([novalidate]) .cardcontainer.error {
  box-shadow: 0 0 0 2px orange;
}

.inputfield input:not(:required)::placeholder {
  font-style: italic;
}

.inputfield select {
  background-position: 96% center;
  background-repeat: no-repeat;
  background-size: 10px 7px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 7"><path d="M10 7L5.046 0 0 7z" transform="rotate(180 5 3.6)" /></svg>');
}

.inputfield input:not(:focus):not(:disabled):hover,
.inputfield select:not(:focus):not(:disabled):hover,
.inputfield .cardcontainer:hover {
  background-color: #eee;
}

.inputfield input:focus,
.inputfield select:focus,
.inputfield .cardcontainer.focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 0 2px #aa8941;
}

.inputfield select:disabled {
  color: #4f4f4f;
}

.inputfield .half {
  width: 130px !important;
}

.inputfield .half + input,
.inputfield .half + select {
  margin-left: 10px;
  width: 130px;
}

.inputfield label {
  display: block;
  padding: .4em 0;
}

.inputfield > div {
  color: #777;
  font-size: .875em;
  margin: .5em 0;
}

.inputfield button {
  min-width: 2.62em;
}

.inputfield > button:first-child {
  margin-left: -1px;
}

.inputfield .preicon {
  position: relative;
  z-index: 4;
  margin: 0 calc(-1.5em - 6px) 0 6px;
  width: 1.5em;
  height: 1.5em;
  border: 1px solid #ddd;
  border-radius: 50%;
  vertical-align: 0%;
  line-height: 1.2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
}

.inputfield .preicon svg {
  width: .8em;
  height: .8em;
  transition: transform .2s ease-out;
  transform: translate(-.02em, 0em);
}

.inputfield .preicon.right svg {
  transform: rotate(180deg) translate(-.07em, 0em);
}

.inputfield .preicon + input {
  padding-left: 2.2em
}

ul.inputfield {
  padding: 0;
}

ul.inputfield li {
  list-style-type: none;
}

ul.inputfield li a {
  margin: 0 -.5em !important;
  padding: .3em .5em .55em !important;
}

.fiat .preicon svg {
  fill: #888888;
}

.gold .preicon svg {
  fill: #F6C358;
}

.revealpassword {
  padding: 0;
  margin: 0 .3em;
  border: 0 !important;
  width: 2.4em;
  height: 2.4em;
  vertical-align: -69%;
}

.copy {
  padding: 0;
  margin: 0 .3em;
  width: 2.4em;
  height: 2.4em;
  vertical-align: 8%;
}

input + .copy {
  vertical-align: -60%;
}

.quickselect {
  height: 2.45em;
  margin: 0 0 0 .3em;
  padding-left: .5em;
  padding-right: .5em;
  vertical-align: 5%;
}

.revealpassword img {
  width: 23px;
  height: 38px;
}

.options {
  display: flex;
  justify-content: space-evenly;
  max-width: 800px;
  margin: 0 -.5em 1em;
}

.options hr  {
  flex: 0;
  margin: 0;
  border: 0;
}

.options button {
  border: none !important;
  border-radius: .3em .3em 0 0 !important;
  border-bottom: 1px solid #ddd !important;
  box-shadow: none;
  background: none;
  padding: .5em .65em calc(.45em + 1px);
  margin: 0;
  flex: 1;
  color: #555 !important;
  transition: none;
}

.options button:hover,
.options button.active,
.options button:active,
.options button:focus {
  background-color: transparent;
  border-bottom: 2px solid #ccc !important;
  color: #222 !important;
  padding-bottom: .45em;
  box-shadow: none !important;
}

.options button.active,
.options button:active {
  border-bottom-color: #bca36c !important;
}

.options button:active {
  background-color: rgba(0, 0, 0, .04);
}

.options button:focus {
  border-bottom: 0px !important;
  box-shadow: inset 0 0 0 2px #837652 !important;
  padding-bottom: calc(.45em + 2px);
}


@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 157px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
  
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.success svg {
  width: 100px;
  height: 100px;
  stroke-dasharray: 157px 157px;
  stroke: #bca36c;
  animation: scaleAnimation 1s ease-out 0s 1 both;
}

.success circle {
  animation: drawCircle 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both,
            fadeOut 0.3s linear 0.9s 1 both;
}

.success polyline {
  stroke-dasharray: 36px 36px;
  stroke: #bca36c;
  animation: drawCheck 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both,
            fadeOut 0.3s linear 0.9s 1 both;
}

.success path {
  fill: #bca36c;
  opacity: 0;
  animation: fadeIn 0.3s linear 0.9s both;
}

a.right {
  float: right;
  padding: .5em !important;
  margin: .15em -.5em -.15em !important;
}

.quote {
  border-radius: .8em;
  box-shadow: 0 2px 4px rgba(0,0,0,.2);
  margin: 2px -.5em;
  padding: .5em;
  border: 1px solid #bca36c;
}

.quote > h3 {
  text-align: center;
  margin-bottom: 0;
}

.recipient {
  text-align: center;
  font-size: .7em;
  color: #777;
}

.highlight {
  padding: .5em 0;
  font-size: 2em;
  text-align: center;
}

.buy .highlight,
.deposit .highlight {
  color: #093;
}

.sell .highlight,
.withdraw .highlight {
  color: #ff333a;
}


.lineitem {
  padding: 0 0 .3em;
  font-size: .9em;
  width: 230px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  color: #555;
}

.lineitem output {
  text-align: right;
}

.negative {
  color: #ff333a;
}

.total {
  padding-top: .2em;
  border-top: 1px solid #ccc;
  font-weight: 500;
  color: #222;
}

.accounts {
  margin-top: 2em;
  background: rgba(72, 80, 104, 0.06);
  padding: .5em .6em;
  border-radius: .5em;
}

.accounts > div {
  flex: 1;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: calc(34px + .8em) 5fr 2fr 6fr;
  grid-template-rows: auto auto;
}

.accounts > div:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, .07);
  margin-bottom: .4em;
  padding-bottom: .4em;
}

.accounts label {
  font-size: .875em;
  color: #666;
}

.accounts output {
  position: relative;
  font-size: 1em;
  font-weight: 400;
  align-self: end;
}

.accounts .arrow {
  align-self: center;
  text-align: center;
  grid-row-start: span 2;
  color: #888888;
  font-size: 1.9em;
}

.productamount .goldbar {
  grid-row-start: span 2;
  margin: 0 .5em 0 0;
  height: 61px;
  filter: saturate(1.2) contrast(1.1);
  border-radius: .4em;
  box-shadow: .02em .04em .06em rgba(0,0,0,.3);
}

.cashbalance .dollar {
  grid-row-start: span 2;
  margin: 0 .5em 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888888;
  font-size: 1.5em;
  font-family: Geometria, sans-serif;
  box-shadow: inset 1px 0 1px rgba(255,255,255,.12), 0 1px 2px rgba(0,0,0,.3);
  text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
  background: rgb(59, 62, 70);
  background-image: linear-gradient(to left bottom, #88888899 0%, #88888833 100%);
  border-radius: .25em;
  height: 60px;
  width: 34px;
}

.goldanim {
  width: 160px;
  border-radius: 28px;
  box-shadow: 1px 2px 2px rgba(0,0,0,.4), 6px 12px 10px 0px rgba(0,0,0,.18);
  animation: up 4s .5s ease-out 1;
  opacity: 0;
}

.anim:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: shade 5s 1s ease-in-out 1;
  background-repeat: no-repeat;
  background-position-x: 600px;
  background-image: linear-gradient(105deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .7) 50%, rgba(255, 255, 255, 0) 55%);
}

@keyframes up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  20%, 100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes shade {
  0% {
    background-position-x: 600px;
  }
  50%, 100% {
    background-position-x: -600px;
  }
}