.sticky {
  position: fixed;
  width: 100%;
  max-height: 100vh;
  z-index: 9999;
}

.menu {
  position: relative;
  right: 0;
  width: 100%;
  transition: all .2s ease-out;
}

.nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  animation: intro .35s ease-out 1 forwards;
  opacity: 0;
  transform: translate(20px, 10px);
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-x: hidden;
}

@keyframes intro {
  0%, 33% {
    opacity: 0;
    transform: translate(20px, 10px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.menu::before {
  content: "";
  display: block;
  z-index: 998;
  position: fixed;
  background: #fff;
  box-shadow: 0 0 0 rgba(0,0,0,0);
  top: 0;
  bottom: 0;
  right: 0;
  max-width: 630px;
  width: 100vw;
  transform: translateX(800px);
  transition: all .2s ease-out;
  opacity: 0;
}

.visible .nav {
  display: block;
}

.visible.menu {
  height: 100vh;
  background: rgba(0, 0, 0, .2);
}

.visible.menu::before {
  transform: translateX(0);
  opacity: 1;
  box-shadow: 0 0 50px rgba(0,0,0, .2);
}

button.menubutton, button.accountbutton {
  position: absolute;
  top: 15px;
  right: 1em;
  z-index: 1000;
  width: 35px;
  height: 34px;
  padding: 0;
  border: 0 !important;
  box-shadow: none;
  text-align: right;
  padding: 0 4px;
  transition: all .2s ease-out;
  overflow: hidden;
}

button.accountbutton {
  right: unset;
  left: 1em;
  z-index: 2;
  text-align: left;
  transition: all .2s ease-out;
  padding: 4px .34em;
}

@media (min-width: 800px) {
  button.menubutton:hover, button.accountbutton:hover {
    width: 110px;
  }

  button.menubutton:before, button.accountbutton:before {
    content: attr(title);
    position: absolute;
    top: 6px;
    right: 38px;
    width: 70px;
    font-size: 10px;
    line-height: 1.1em;
    color: #bca36c;
    text-transform: uppercase;
  }

  button.accountbutton:before {
    right: unset;
    left: 38px;
  }
}

.menubutton svg {
  width: 1.66em;
  height: 34px;
}

.menubutton path {
  transition: all .1s linear;
  transform: rotate(0);
  opacity: 1;
}

.visible .menubutton .top {
  transform: rotate(45deg) translate(7px, -9px);
}

.visible .menubutton .center {
  opacity: 0;
}

.visible .menubutton .bottom {
  transform: rotate(-45deg) translate(-15px, -1px);
}

@media (min-width: 1000px) {
  .sticky {
    position: relative;
  }
  .nav {
    display: block;
    position: fixed;
    animation: none;
    transform: none;
    opacity: 1;
    bottom: unset;
    height: auto;
    overflow: visible;
    backdrop-filter: none;
    background-color: rgba(37, 38, 39, 0);
  }
  .nav.background {
    background-color: rgba(37, 38, 39, .94);
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
  }
  .menubutton, .accountbutton, .menu::before {
    display: none;
  }
}

@supports (-webkit-backdrop-filter: blur(8px)) {
  .nav.background {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  button.menubutton, button.accountbutton {
    background-color: transparent;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }
  .menu {
    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);
  }
  .visible.menu {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
}