@font-face {
  font-family: AltoPro;
  src: url("/fonts/AltoPro-Normal.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: AltoPro;
  src: url("/fonts/AltoPro-Bold.woff2") format("woff2");
  font-weight: bold;
  font-display: swap;
}

html, body {
  margin: 0;
  padding: 0;
  background: #252627;
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', system-ui, sans-serif;
  color: #fff;
}

p {
  line-height: 1.2em;
}

b {
  font-weight: 500;
}

h1 {
  font-family: AltoPro, sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-shadow: 0 10px 20px rgba(0,0,0,.4);
}

h2 {
  font-family: AltoPro, sans-serif;
  font-weight: normal;
  margin: .12em auto;
  color: #bca36c;
  max-width: 64vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

h3 {
  font-family: AltoPro, sans-serif;
  margin: .5em 0;
}

h3 input[type=checkbox], p input[type=checkbox] {
  vertical-align: 20%;
}

h4 {
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', system-ui, sans-serif;
  font-size: 1em;
  font-weight: normal;
  color: #ac9460;
}

h5 {
  font-family: AltoPro, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: .75em;
  color: #222;
  margin: 1em 0 .3em;
}

h6 {
  font-family: AltoPro, sans-serif;
  font-weight: normal;
  font-size: 1em;
  margin: .3em 0 .15em;
}

a {
  color: #9c8553;
  border-radius: .3em;
}

a:focus {
  outline: none;
  box-shadow: 0 0 0 2px #aa8941;
}

a:hover {
  color: #aa8941;
}

a:active {
  color: #837652;
  background: rgba(0,0,0,.08);
}


button {
  position: relative;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: .02em;
  line-height: 1.2em;
  box-sizing: border-box;
  color: #eee;
  border: 1px #888888 solid;
  box-shadow: 0 0 0 2px #252627, 0 4px 20px rgba(0,0,0,.3);
  transition: box-shadow .2s ease-out;
  background-color: #252627;
  border-radius: .5em;
  padding: .4em 1.1em .45em;
  cursor: pointer;
}

button.light {
  background-color: transparent;
  border: 1px solid #ddd;
  box-shadow: none;
  color: #777;
  font-weight: normal;
}

button.noborder {
  border-color: transparent;
  padding: .2em;
}

button:hover {
  background-color: #525358;
}

button.light:hover {
  background-color: rgba(0, 0, 0, .04);
}

button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #525358, 0 0 0 4px #bca36c, 0 4px 10px rgba(0,0,0,.5);
}

button.light:focus {
  background-color: transparent;
  box-shadow: 0 0 0 2px #bca36c;
}

button:active {
  background-color: #323031;
  box-shadow: 0 2px 6px rgba(0,0,0,.5), 0 0 0 2px #323031;
}

button.light:active {
  background-color:rgba(0, 0, 0, .07);
  box-shadow: 0 0 0 2px #bca36c;
}

button + button {
  margin-left: .5em;
}

button.pulsate:before {
  content: '';
  position: absolute;
  border: 6px solid rgba(246, 195, 85, 0.5);
  animation: pulse 2s .9s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  opacity: 0;
}

@keyframes pulse {
  0% {
    border-radius: 1em;
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    opacity: 0;
  }
  5% {
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    opacity: 1;
  }
  70%, 100% {
    border-radius: 1.2em;
    top: -20px;
    left: -20px;
    bottom: -20px;
    right: -20px;
    opacity: 0;
  }
}

.goldenshimmer {
  border-color: #F6C358;
  animation: shimmer 8s linear infinite;
  background-size: 300%;
  background-position-x: 0;
  background-image: linear-gradient(109deg, rgba(255,218,0,0) 31%, rgba(237,183,0,.3) 50%, rgba(255,234,112,.4) 50%, rgba(255,218,0,0) 64%);
}

.loadingshimmer {
  background-size: 300px 100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(109deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.15) 60%,  rgba(0,0,0,0) 100%);
  animation: flow 1s linear infinite;
}

@keyframes flow {
  0% {
    background-position-x: -300px;
  }
  100% {
    background-position-x: 600px;
  }
}

@keyframes shimmer {
  0%, 84% {
    background-position-x: 0;
    background-size: 300%;
  }
  93% {
    background-size: 1000%;
  }
  100% {
    background-size: 300%;
    background-position-x: 100%;
  }
}